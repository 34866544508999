import React from "react";
import ContentLayout from '../layout/content';

export default () => {

    return <ContentLayout>
        <h1>About Humility is...</h1>
        <p>
            This site is dedicated to helping people, primarily those seeking Christ, to come to a fuller working definition of humility that they can apply in their every day life.
        </p>
        <p>
            In <i>Humility</i>, Andrew Murray repeatedly draws our attention to the example of Christ's humility and its effectiveness. He makes the case that we desperately need to regain our understanding of what humility is in order to carry out the commands that were set before those of us who profess to follow Christ: to love God and to love our neighbor as ourselves.
        </p>
        <p>
            Currently the content of this site is split into three main categories riches, honor, and life. These categories are drawn from Proverbs 22:4 which also serves as a broad definition of humility and it's benefits
        </p>
        <blockquote className = 'tc'>
            Humility is the fear of the Lord; its wages are riches and honor and life.<br />- Proverbs 22:4
        </blockquote>
        <p>
            Each article is focused on a particular theme to help us see how humility would play out tangibly in a specific situation and how it leads to the rewards listed in Proverbs.
        </p>
    </ContentLayout>
}